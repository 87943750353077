import Vue from 'vue'

export default Vue.extend({
  template: `
        <div>
          <input style="width: 100%" type="text" disabled>
        </div>
    `,
  data: function () {
    return {
      isActive: false
    }
  },
  methods: {
    toggleFilter (isFilterActive) {
      this.isActive = isFilterActive
      this.params.parentFilterInstance((instance) =>
        instance.onFloatingFilterChanged(isFilterActive)
      )
    },
    onParentModelChanged (model) {
      this.isActive = !!model
    }
  }
})
